import isEmpty from 'lodash/isEmpty';
import { clearCurrentUser, fetchCurrentUser } from './user.duck';
import { storableError } from '../util/errors';
import * as log from '../util/log';
import { sendVerification, checkVerification, upsertSubscriberInSegment, fetchReferredUsers, validateReferralCode } from '../util/api';
import { AmplitudeAnalytics, logEvent, logEventSetUserID, logIdentify, logPixelFacebook } from '../util/logsEvent';

const authenticated = authInfo => authInfo && authInfo.isAnonymous === false;

// ================ Action types ================ //

export const AUTH_INFO_REQUEST = 'app/Auth/AUTH_INFO_REQUEST';
export const AUTH_INFO_SUCCESS = 'app/Auth/AUTH_INFO_SUCCESS';

export const LOGIN_REQUEST = 'app/Auth/LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'app/Auth/LOGIN_SUCCESS';
export const LOGIN_ERROR = 'app/Auth/LOGIN_ERROR';

export const VALIDATE_USERNAME_REQUEST = 'app/InboxPage/VALIDATE_USERNAME_REQUEST';
export const VALIDATE_USERNAME_SUCCESS = 'app/InboxPage/VALIDATE_USERNAME_SUCCESS';
export const VALIDATE_USERNAME_ERROR = 'app/InboxPage/VALIDATE_USERNAME_ERROR';

export const LOGOUT_REQUEST = 'app/Auth/LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'app/Auth/LOGOUT_SUCCESS';
export const LOGOUT_ERROR = 'app/Auth/LOGOUT_ERROR';

export const SIGNUP_REQUEST = 'app/Auth/SIGNUP_REQUEST';
export const SIGNUP_SUCCESS = 'app/Auth/SIGNUP_SUCCESS';
export const SIGNUP_ERROR = 'app/Auth/SIGNUP_ERROR';

export const SEND_VERIFICATION_REQUEST = 'app/Auth/SEND_VERIFICATION_REQUEST';
export const SEND_VERIFICATION_SUCCESS = 'app/Auth/SEND_VERIFICATION_SUCCESS';
export const SHOW_USER_ERROR = 'app/ProfilePage/SHOW_USER_ERROR';

export const CHECK_VERIFICATION_REQUEST = 'app/Auth/CHECK_VERIFICATION_REQUEST';
export const CHECK_VERIFICATION_SUCCESS = 'app/Auth/CHECK_VERIFICATION_SUCCESS';

// Generic user_logout action that can be handled elsewhere
// E.g. src/reducers.js clears store as a consequence
export const USER_LOGOUT = 'app/USER_LOGOUT';

// ================ Reducer ================ //

const initialState = {
  isAuthenticated: false,

  // scopes associated with current token
  authScopes: [],

  // auth info
  authInfoLoaded: false,

  // login
  loginError: null,
  loginInProgress: false,

  // logout
  logoutError: null,
  logoutInProgress: false,

  // signup
  signupError: null,
  signupInProgress: false,

  //whatsapp verification
  status: null,
  phoneNumber: null,

  validateUserNameInProgress: false,
  validateUserNameError: null,
  userNameAvailable: true
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case AUTH_INFO_REQUEST:
      return state;
    case AUTH_INFO_SUCCESS:
      return {
        ...state,
        authInfoLoaded: true,
        isAuthenticated: authenticated(payload),
        authScopes: payload.scopes,
      };

    case LOGIN_REQUEST:
      return {
        ...state,
        loginInProgress: true,
        loginError: null,
        logoutError: null,
        signupError: null,
      };
    case LOGIN_SUCCESS:
      return { ...state, loginInProgress: false, isAuthenticated: true };
    case LOGIN_ERROR:
      return { ...state, loginInProgress: false, loginError: payload };

    case LOGOUT_REQUEST:
      return { ...state, logoutInProgress: true, loginError: null, logoutError: null };
    case LOGOUT_SUCCESS:
      return { ...state, logoutInProgress: false, isAuthenticated: false, authScopes: [] };
    case LOGOUT_ERROR:
      return { ...state, logoutInProgress: false, logoutError: payload };

    case SIGNUP_REQUEST:
      return { ...state, signupInProgress: true, loginError: null, signupError: null };
    case SIGNUP_SUCCESS:
      return { ...state, signupInProgress: false };
    case SIGNUP_ERROR:
      return { ...state, signupInProgress: false, signupError: payload };

    case SEND_VERIFICATION_SUCCESS:
      return {
        ...state,
        status: payload.response.status,
        phoneNumber: payload.response.phoneNumber,
      };

    case CHECK_VERIFICATION_SUCCESS:
      return { ...state, status: payload.response.status };

    case VALIDATE_USERNAME_REQUEST:
      return { ...state, validateUserNameInProgress: true, validateUserNameError: null };
      
    case VALIDATE_USERNAME_SUCCESS: 
      return {
        ...state,
        userNameAvailable: payload.totalItems === 0,
        validateUserNameInProgress: false,
      };
    case VALIDATE_USERNAME_ERROR:
      return { ...state, validateUserNameInProgress: false, validateUserNameError: payload };
    default:
      return state;
  }
}

// ================ Selectors ================ //

export const authenticationInProgress = state => {
  const { loginInProgress, logoutInProgress, signupInProgress } = state.Auth;
  return loginInProgress || logoutInProgress || signupInProgress;
};

// ================ Action creators ================ //

export const authInfoRequest = () => ({ type: AUTH_INFO_REQUEST });
export const authInfoSuccess = info => ({ type: AUTH_INFO_SUCCESS, payload: info });

export const loginRequest = () => ({ type: LOGIN_REQUEST });
export const loginSuccess = () => ({ type: LOGIN_SUCCESS });
export const loginError = error => ({ type: LOGIN_ERROR, payload: error, error: true });

export const logoutRequest = () => ({ type: LOGOUT_REQUEST });
export const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
export const logoutError = error => ({ type: LOGOUT_ERROR, payload: error, error: true });

export const signupRequest = () => ({ type: SIGNUP_REQUEST });
export const signupSuccess = () => ({ type: SIGNUP_SUCCESS });
export const signupError = error => ({ type: SIGNUP_ERROR, payload: error, error: true });

export const userLogout = () => ({ type: USER_LOGOUT });

export const sendVerifRequest = status => ({
  type: SEND_VERIFICATION_REQUEST,
  payload: status,
});
export const sendVerifSuccess = response => ({
  type: SEND_VERIFICATION_SUCCESS,
  payload: { response },
});
export const checkVerifRequest = status => ({
  type: CHECK_VERIFICATION_REQUEST,
  payload: status,
});
export const checkVerifSuccess = response => ({
  type: CHECK_VERIFICATION_SUCCESS,
  payload: { response },
});
export const showUserError = e => ({
  type: SHOW_USER_ERROR,
  error: true,
  payload: e,
});


const validateUserNameRequest = () => ({ type: VALIDATE_USERNAME_REQUEST });

const validateUserNameSuccess = response => ({
  type: VALIDATE_USERNAME_SUCCESS,
  payload: response,
});

const validateUserNameError = e => ({
  type: VALIDATE_USERNAME_ERROR,
  error: true,
  payload: e,
});


// ================ Thunks ================ //

export const authInfo = () => (dispatch, getState, sdk) => {
  dispatch(authInfoRequest());
  return sdk
    .authInfo()
    .then(info => dispatch(authInfoSuccess(info)))
    .catch(e => {
      // Requesting auth info just reads the token from the token
      // store (i.e. cookies), and should not fail in normal
      // circumstances. If it fails, it's due to a programming
      // error. In that case we mark the operation done and dispatch
      // `null` success action that marks the user as unauthenticated.
      log.error(e, 'auth-info-failed', {}, 'user');
      dispatch(authInfoSuccess(null));
    });
};

export const login = (username, password) => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(loginRequest());

  // Note that the thunk does not reject when the login fails, it
  // just dispatches the login error action.
  return sdk
    .login({ username, password })
    .then(async () => {
      const amplitude = new AmplitudeAnalytics();
      const user = await sdk.currentUser.show();
      amplitude.setUser(user.data.data.id.uuid);
      amplitude.trackEvent('Last Login', {
        email: username,
        username: user.data.data.attributes.profile.displayName,
      });
      logEvent('Login Completed');
      logIdentify('is authenticated', true);
      logIdentify('is seller', user.data.data.attributes.profile.metadata.seller);
      dispatch(loginSuccess());
    })
    .then(() => dispatch(fetchCurrentUser()))
    .catch(e => dispatch(loginError(storableError(e))));
};

export const logout = () => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(logoutRequest());

  // Note that the thunk does not reject when the logout fails, it
  // just dispatches the logout error action.
  return sdk
    .logout()
    .then(async () => {
      // The order of the dispatched actions
      const amplitude = new AmplitudeAnalytics();
      logEvent('session ended');
      logIdentify('is authenticated', false);
      await amplitude.cleanSession();
      dispatch(logoutSuccess());
      dispatch(clearCurrentUser());
      log.clearUserId();
      dispatch(userLogout());
    })
    .catch(e => dispatch(logoutError(storableError(e))));
};

export const signup = params => (dispatch, getState, sdk) => {
  if (authenticationInProgress(getState())) {
    return Promise.reject(new Error('Login or logout already in progress'));
  }
  dispatch(signupRequest());
  const { email, password, firstName, lastName, phoneNumber, referredCode, userName, ...rest } = params;

  const createUserParams = isEmpty(rest)
    ? { email, password, firstName, lastName, privateData: { phoneNumber, referredCode },  publicData: {userName}}
    : {
      email,
      password,
      firstName,
      lastName,
      publicData: { ...rest, followersCount: 0, followingCount: 0, transaction: 0, userName },
    };

  // We must login the user if signup succeeds since the API doesn't
  // do that automatically.
  return sdk.currentUser
    .create(createUserParams)
    .then(responseUser => {
      const today = new Date();
      const idUser = responseUser.data.data.id.uuid;
      logEventSetUserID(idUser);
      logIdentify('email', email);
      logIdentify('phone number', phoneNumber);
      logIdentify('account creation date', today.toISOString());
      logIdentify('name', `${firstName} ${lastName}`);
      logIdentify('is seller', false);
      logIdentify('products for sale', 0);
      logIdentify('products sold', 0);
      logIdentify('products purchased', 0);
      logIdentify('is authenticated', true);
      logIdentify('products favorited', 0);
      logEvent('Sign Up Completed');
      logPixelFacebook('CompleteRegistration');

      upsertSubscriberInSegment({
        email,
        lastName,
        firstName,
        segmentIds: ['619eb2eea487768a9b3675b5'],
      });
      dispatch(signupSuccess());
    })
    .then(() => dispatch(login(email, password)))
    .catch(e => {
      dispatch(signupError(storableError(e)));
      log.error(e, 'signup-failed', {
        email: params.email,
        firstName: params.firstName,
        lastName: params.lastName,
      }, {}, 'user');
    });
};

export const sendVerif = phone => (dispatch, getState, sdk) => {
  dispatch(sendVerifRequest(true));
  return sendVerification( phone )
    .then(response => {
      dispatch(sendVerifSuccess(response));
      dispatch(sendVerifRequest(false));
      return response;
    })
    .catch(e => {
      dispatch(showUserError(storableError(e)));
      dispatch(sendVerifRequest(false));
    });
};

export const checkVerif = (phone, code) => (dispatch, getState, sdk) => {
  dispatch(checkVerifRequest(true));
  return checkVerification({ phone, code })
    .then(response => {
      dispatch(checkVerifSuccess(response));
      dispatch(checkVerifRequest(false));
      return response;
    })
    .catch(e => {
      dispatch(showUserError(storableError(e)));
      dispatch(checkVerifRequest(false));
    });
};

export const validateReferredCode = (code) => (dispatch, getState, sdk) => {
  dispatch(validateUserNameRequest())
  return validateReferralCode({code, validation:'exists'}).then(res => {
    dispatch(validateUserNameSuccess(res))
      return res
  }).catch(e => {
    dispatch(validateUserNameError(e))
  })
} 