import Decimal from 'decimal.js';
import fetch from 'node-fetch';
import config from '../config';
import { transit, types as sdkTypes } from './sdkLoader';
const fetchApi = typeof window !== 'undefined' ? window.fetch : fetch;

const apiBaseUrl = () => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if(process.env.REACT_APP_DEV_API_URL){
    return `${process.env.REACT_APP_DEV_API_URL}`;
  }
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }


  if (typeof window === 'undefined' || !window.location) {
    return process.env.REACT_APP_CANONICAL_ROOT_URL || '';
  };

  // Otherwise, use the same domain and port as the frontend
  return `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: config.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};
const get = (path, params = {}) => {
  const url = `${apiBaseUrl()}${path}?` + new URLSearchParams(params);
  const options = {
    method: 'GET',
    credentials: 'include',
  };
  return fetchApi(url, options)
    .then(res => {
      if (res.status >= 400) {
        const e = new Error('Local API request failed');
        e.apiResponse = res;
        throw e;
      }
      return res;
    })
    .then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
}
const post = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/transit+json',
    },
    body: serialize(body),
  };

  return fetchApi(url, options)
    .then(res => {
      if (res.status >= 400) {
        // Parse the error body
        return res.json().then(errorBody => {
          const e = new Error('Local API request failed');
          e.apiResponse = res;
          e.status = res.status;   // Include the status code
          e.details = errorBody;   // Include the error details from the response
          throw e;
        });
      }
      return res;
    })
    .then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
};

const postFormData = (path, body) => {
  const url = `${apiBaseUrl()}${path}`;
  const options = {
    method: 'POST',
    credentials: 'include',
    body: body,
  };
  return fetchApi(url, options)
    .then(res => {
      if (res.status >= 400) {
        const e = new Error('Local API request failed');
        e.apiResponse = res;
        throw e;
      }
      return res;
    })
    .then(res => {
      const contentTypeHeader = res.headers.get('Content-Type');
      const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;
      if (contentType === 'application/transit+json') {
        return res.text().then(deserialize);
      } else if (contentType === 'application/json') {
        return res.json();
      }
      return res.text();
    });
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the booking data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

export const transitionTransaction = body => {
  return post('/api/transition-transaction', body);
}

export const likeProduct = body => {
  return post('/api/like-product', body);
};

export const sendEditTransaction = body => {
  return post('/api/edit-transaction', body);
};

export const sendEditTransactionById = body => {
  return post('/api/edit-transaction-by-id', body);
};

export const editTransactionShippingMethod = body => {
  return post('/api/edit-transaction-shipping-method', body);
};

export const validateEnquiryTransaction = body => {
  return post('/api/validate-enquiry-transaction', body);
};

export const getFacebookCatalogue = body => {
  return post('/api/facebook_catalogue', body);
}

export const addComment = body => {
  return post('/api/add-comment', body);
}

export const sendMessageWhatsapp = body => {
  return post('/api/send-message-notification', body);
}
export const deleteLike = body => {
  return post('/api/delete-like', body);
}

export const getAllFavoritesListings = body => {
  return post('/api/get-all-favorites-listings', body);
}

export const sendVerification = body => {
  return post('/api/send-verification', body);
}

export const checkVerification = body => {
  return post('/api/check-verification', body);
}

export const getUserByPhone = body => {
  return post('/api/get-user-by-phone', body);
}

export const saveEmailBD = body => {
  return post('/api/save-email-suscription', body);
}

export const getAddress = body => {
  return post('/api/get-address', body);
}

export const getShippingPrice = body => {
  return post('/api/get-shipping-price', body);
}

export const apiFollowUser = body => {
  return post('/api/follow-user', body);
}

export const apiGetFollowers = body => {
  return post('/api/get-followers', body);
}

export const apiGetFollowing = body => {
  return post('/api/get-following', body);
}

export const apiGetTransactions = body => {
  return post('/api/get-transactions', body);
}

export const apiGetOrders = body => {
  return post('/api/get-orders', body);
}

export const isZipAvailable = body => {
  return post('/api/get-available', body);
}

export const apiGetFullUser = body => {
  return post('/api/get-full-user', body);
}

export const apiGetListingTransactions = body => {
  return post('/api/get-transactions-items', body);
}

export const apiSetAsSold = body => {
  return post('/api/set-as-sold', body);
}

export const setListingNewPriceDiscount = body => {
  return post('/api/set-listing-new-price-discount', body);
}


export const apiMarkMessagesAsRead = body => {
  return post('/api/mark-messages-as-read', body);
}

export const apiGetListingAvailable = body => {
  return post('/api/get-transaction-available', body);
}

export const uploadCoverImage = body => {
  return postFormData('/api/upload-cover-picture', body);
}

export const setReceived = body => {
  return post('/api/set-received', body);
}

export const addUsersShoppingCart = body => {
  return post('/api/add-users-shopping-cart', body);
}

export const deleteUsersShoppingCart = body => {
  return post('/api/delete-users-shopping-cart', body);
}

export const setOpenCase = body => {
  return post('/api/set-open-case', body);
}

export const updateOwnListing = body => {
  return post('/api/update-own-listing', body);
}

export const verifySeller = body => {
  return post('/api/verify-seller', body);
}

export const apiSearchListings = body => {
  return post('/api/search-listings-meili', body)
}

export const checkMeiliSearchHealth = body => {
  return post('/api/meilisearch/health', body)
  
}

export const searchBrandsMeili = body => {
  return post('/api/search-brands-meili', body)
}




export const updateUser = body => {
  return post('/api/update-user', body);
}

export const validateReferralCode = body => {
  return post('/api/validate-referral-code', body);

}

export const updateTransactionMetadata = body => {
  return post('/api/update-transaction-metadata', body);
}

export const readNotifications = body => {
  return post('/api/mark-notifications-as-read', body);
}
export const getNotifications = (params) => {
  return get('/api/get-notifications', params);
}

export const notifications = (params) => {
  return get('/api/notifications', params);
}

export const triggerSold = body => {
  return post('/api/filter-by-sold', body);
}

export const getSoldAttribute = body => {
  return post('/api/get-sold-attribute', body);
}

export const apiSetFollowingData = body => {
  return post('/api/set-follwing-data', body);
}

export const searchIndexAlgolia = body => {
  return post('/api/search-index-algolia', body);
}

export const recalculateLineItems = body => {
  return post('/api/recalculate-line-items', body);
};


export const fetchPendingTransactions = body => {
  return post('/api/fetch-pending-transactions', body);
};

export const getShippingQuotation = body => {
  return post('/api/get-shipping-quotation', body);
};

export const createPaypalOrderApi = body => {
  return post('/api/paypal-api/create-order', body);
}

export const approvePaypalPaymentApi = body => {
  return post('/api/paypal-api/approve-payment', body);
}

export const handlePendingClabes = body => {
  return post('/api/handle-pending-clabes', body);
}

export const handleListingAvailability = body => {
  return post ('/api/handle-listing-availability', body);
}

export const saveReferredUser = body => {
  return post('/api/save-referred-user', body);
}

export const createStripeCharge = body => {
  return post('/api/stripe-api/create-stripe-charge', body);
}

export const createOneTimeStripeCharge = body => {
  return post('/api/stripe-api/create-one-time-stripe-charge', body);
}

export const deleteStripePaymentMethod = body => {
  return post('/api/stripe-api/delete-stripe-payment-method', body);
}

export const saveStripePaymentMethod = body => {
  return post('/api/stripe-api/save-stripe-payment-method', body);
}

export const createNewStripeCharge = body => {
  return post('/api/stripe-api/create-new-stripe-charge', body);

}

export const fetchStripeCustomer = body => {
  return post('/api/stripe-api/fetch-stripe-customer', body);
}

export const fetchReferredUsers = body => {
  return post('/api/fetch-referred-users', body);
}

export const createStripePayout = body => {
  return post('/api/stripe-api/create-stripe-payout', body);
}

export const createSetupIntent = body => {
  return post('/api/stripe-api/create-setup-intent', body);
}

export const captureOffer = body => {
  return post('/api/stripe-api/capture-offer', body);
}

export const fetchApiTransfers = body => {
  return post('/api/stripe-api/fetch-api-transfers', body);
};

export const fetchStatusForTransfer = body => {
  return post('/api/stripe-api/fetch-status-for-transfers', body);
};

export const calculateTransferPagination = body => {
  return post('/api/stripe-api/calculate-transfers-pagination', body);
};

export const createReferralCodeLink = body => {
  return post('/api/create-referral-code-link', body);
}

export const createUsername = body => {
  return post('/api/create-username', body);
}

export const validateDistinctUserName = body => {
  return post('/api/validate-username', body);
}

export const emailHandler = body => {
  return post('/api/email-handler', body);
}

export const upsertSubscriberInSegment = body => {
  return post ('/api/flodesk/add-subscriber', body);
}

export const deleteSubscriberInSegment = body => {
  return post ('/flodesk/delete-subscriber-segment', body);
}

export const getListingByUser = body => {
  return post ('/api/get-listings', body);
}

export const getListingById = body => {
  return post ('/api/get-listing-id', body);
}

export const getCategories = body => {
  return post ('/api/categories', body);
}

export const getSections = body => {
  return post ('/api/category', body);
}

export const logout = body => {
  return post('/api/logout', body);
}

export const login = body => {
  return post('/api/login', body);
}

export const signup = body => {
  return post('/api/signup', body);
}