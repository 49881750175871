import classNames from 'classnames';
import { bool, func, shape, string } from 'prop-types';
import React from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { compose } from 'redux';
import { Button, FieldCurrencyInput, FieldTextInput, Form } from '../../../components';
import config from '../../../config';
import { formatMoney } from '../../../util/currency';
import { FormattedMessage, injectIntl, intlShape } from '../../../util/reactIntl';
import { types as sdkTypes } from '../../../util/sdkLoader';
import { findOptionsForSelectFilter } from '../../../util/search';
import { propTypes } from '../../../util/types';
import { composeValidators, maxLength, moneySubUnitAmountAtLeast, moneySubUnitAmountAtMost, required } from '../../../util/validators';
import { searchBrandsMeili } from '../../../util/api';
import ReloveCategorySelectField from './ReloveCategorySelectField';
import ReloveColorSelectField from './ReloveColorSelectField';
import ReloveDatalistField from './ReloveDatalistField'; // Ensure this is imported correctly
import css from './ReloveEditListingForm.css';
import ReloveFeatureSelectField from './ReloveFeatureSelectField';
import ReloveFeatureSelectFieldSimple from './ReloveFeatureSelectFieldSimple';
import RelovePhotosDropzone from './RelovePhotosDropzone';
import ReloveSizeSelectField from './ReloveSizeSelectField';
import classnames from 'classnames';
const { Money } = sdkTypes;
const TITLE_MAX_LENGTH = 80;

// Define the forbidden brands
const forbiddenBrands = [
  'Forever 21', 'Shein', 'C&A', 'Old Navy', 'Lefties', 'Ali', 
  'Alibaba', 'Aliexpress', 'Primark', 'Rue 21', 'Rue21', 
  'Papaya', 'Shasa', 'Cklass', 'Ali Express', 'Aliexrpess', 
  'She1n', 'Pretty Little Thing', 'Xhilaration', 'Agaci', 
  'Agaçi', 'Hollister', 'Holister', 'JOA Los Angeles', 'Oysho', 
  'Aerie', 'American Apparel', 'Boohoo', 'ONE 33 SOCIAL', 
  'Fashion Nova', 'AEROPOSTALE', 'Aéropostale', 'Xoxo', 
  'Cuidado Con El Perro', 'Zaful', 'PrettyLittleThing', 
  'Arantza', 'Love Tree', 'Jealous Tomato', 'Schutique', 
  "That's It", 'Thats it', 'Dressbarn', 'A Gaci', 'Sexy Jeans', 'Forever XXI', 'Romwe'
];

const ReloveEditListingFormComponent = props => {
  const [brandOptions, setBrandOptions] = React.useState([]);  // State to store search results from MeiliSearch


  const normalizeString = input => input.toLowerCase().replace(/\s+/g, '').replace(/[\.\-]/g, '');


  const searchBrands = async (query) => {
    if (query) {
      const normalizedQuery = normalizeString(query);
  
      try {
        const searchResults = await searchBrandsMeili({ queryString: normalizedQuery });
  
        setBrandOptions(searchResults.brandOptions.map(hit => ({
          key: hit.key,
          label: hit.label,
          banned: hit.banned,
        })));
      } catch (error) {
        console.error('Error fetching brands:', error);
        setBrandOptions([]);
      }
    } else {
      setBrandOptions([]);
    }
  };
  
  const customItemRenderer = ({ item, itemIndex, props, state, methods }) => {
    return (
      <div className={css.itemState} onClick={() => methods.addItem(item)}>
        <div className={classnames({ [css.itemSelected]: methods.isSelected(item) })}>
          <h6>{item.label}</h6>
          <p>{item.text}</p>
        </div>
      </div>
    )
  };

  return (
    <FinalForm
      {...props}
      render={formRenderProps => {
        const {
          className,
          disabled,
          fetchErrors,
          filterConfig,
          form,
          handleSubmit,
          images,
          intl,
          invalid,
          onImageUpload,
          onRemoveImage,
          imageOrder,
          onUpdateImageOrder,
          pristine,
          ready,
          saveActionMsg,
          updateInProgress,
          updated,
          maxPrice
        } = formRenderProps;

        // All the localized messages for form fields
        const titleMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.title' });
        const titlePlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.titlePlaceholder' });
        const titleTooltipMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.titleTooltip' });
        const titleRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.titleRequired', });
        const maxLengthMessage = intl.formatMessage(
          { id: 'EditListingDescriptionForm.maxLength' },
          {
            maxLength: TITLE_MAX_LENGTH,
          }
        );
        const descriptionMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.description' });
        const descriptionPlaceholderMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionPlaceholder' });
        const maxLength60Message = maxLength(maxLengthMessage, TITLE_MAX_LENGTH);
        const descriptionRequiredMessage = intl.formatMessage({ id: 'EditListingDescriptionForm.descriptionRequired' });

        // Options for selects
        const clothesSizeOptions = findOptionsForSelectFilter('size', filterConfig);
        const shoesSizeOptions = findOptionsForSelectFilter('sizeShoes', filterConfig);
        const accessoriesSizeOptions = findOptionsForSelectFilter('sizeAccessories', config.custom.filters);
        const sizeLabel = intl.formatMessage({ id: 'EditListingFeaturesForm.sizeLabel' });
        const sizePlaceholder = intl.formatMessage({ id: 'EditListingFeaturesForm.sizePlaceholder' });
        const sizeRequired = required(
          intl.formatMessage({ id: 'EditListingFeaturesForm.sizeRequired' })
        );

        const categoryOptions = findOptionsForSelectFilter('category', filterConfig);
        const categoryLabel = intl.formatMessage({ id: 'EditListingFeaturesForm.categoryLabel' });
        const categoryPlaceholder = intl.formatMessage({ id: 'EditListingFeaturesForm.categoryPlaceholder' });
        const categoryRequired = required(
          intl.formatMessage({ id: 'EditListingFeaturesForm.categoryRequired' })
        );

        const styleOptions = findOptionsForSelectFilter('style', filterConfig);
        const styleLabel = intl.formatMessage({ id: 'EditListingFeaturesForm.styleLabel' });
        const stylePlaceholder = intl.formatMessage({ id: 'EditListingFeaturesForm.stylePlaceholder' });

        const colorOptions = findOptionsForSelectFilter('color', filterConfig);
        const colorLabel = "Color";
        const colorPlaceholder = "Selecciona el color";
        const colorRequired = required("Necesitas seleccionar un color.");

        const brandLabel = intl.formatMessage({ id: 'EditListingFeaturesForm.brandLabel' });
        const brandPlaceholder = intl.formatMessage({ id: 'EditListingFeaturesForm.brandPlaceholder' });
        const brandRequired = required(
          intl.formatMessage({ id: 'EditListingFeaturesForm.brandRequired' })
        );

        const validateBrand = (value) => {
          if (!value) {
            return 'Brand is required.';
          }

          // Check if the selected brand is banned
          const selectedBrand = brandOptions.find(option => option.label === value);
          if (selectedBrand && selectedBrand.banned === 'yes') {
            return 'No aceptamos esta marca en gloset!';
          }

          return undefined;
        };

        const useOptions = findOptionsForSelectFilter('use', filterConfig);
        const useLabel = intl.formatMessage({ id: 'EditListingFeaturesForm.useLabel' });
        const usePlaceholder = intl.formatMessage({ id: 'EditListingFeaturesForm.usePlaceholder' });
        const useRequired = required(
          intl.formatMessage({ id: 'EditListingFeaturesForm.brandRequired' })
        );

        const availableOptions = findOptionsForSelectFilter('available', filterConfig);
        const availableLabel = intl.formatMessage({ id: 'EditListingFeaturesForm.availableLabel' });
        const availablePlaceholder = intl.formatMessage({ id: 'EditListingFeaturesForm.availablePlaceholder' });

        const originalPriceLabel = intl.formatMessage({ id: 'EditListingPricingForm.originalPriceLabel' });
        const originalPricePlaceholder = intl.formatMessage({ id: 'EditListingPricingForm.originalPricePlaceholder' });
        const originalPriceRequired = required(
          intl.formatMessage({ id: 'EditListingPricingForm.originalPriceRequired' })
        );

        const pricePerUnitMessage = intl.formatMessage({ id: 'EditListingPricingForm.pricePerUnit' });
        const pricePlaceholderMessage = intl.formatMessage({ id: 'EditListingPricingForm.priceInputPlaceholder' });
        const priceRequired = required(
          intl.formatMessage({ id: 'EditListingPricingForm.priceRequired' })
        );
        const minPrice = new Money(config.listingMinimumPriceSubUnits, config.currency);
        const minPriceRequired = moneySubUnitAmountAtLeast(
          intl.formatMessage(
            { id: 'EditListingPricingForm.priceTooLow' },
            { minPrice: formatMoney(intl, minPrice) }
          ),
          config.listingMinimumPriceSubUnits
        );

        const maxPriceRequired = moneySubUnitAmountAtMost(
          intl.formatMessage(
            {
              id: 'EditListingPricingForm.priceTooHigh',
            },
            {
              maxPrice: formatMoney(intl, maxPrice ? maxPrice : new Money(2000000000000, config.currency)),
            }
          ),
          maxPrice ? maxPrice.amount : 2000000000000
        );
        const priceValidators = config.listingMinimumPriceSubUnits
          ? composeValidators(priceRequired, minPriceRequired, maxPriceRequired)
          : priceRequired;

        const { updateListingError, createListingDraftError, showListingsError } = fetchErrors || {};
        const errorMessageUpdateListing = updateListingError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.updateFailed" />
          </p>
        ) : null;

        const errorMessageCreateListingDraft = createListingDraftError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.createListingDraftError" />
          </p>
        ) : null;

        const errorMessageShowListing = showListingsError ? (
          <p className={css.error}>
            <FormattedMessage id="EditListingDescriptionForm.showListingFailed" />
          </p>
        ) : null;

        const classes = classNames(css.root, className);
        const hasImages = Array.isArray(images) && images.length;
        const submitReady = (updated && pristine) || ready;
        const submitInProgress = updateInProgress;
        const submitDisabled = invalid || disabled || submitInProgress || !hasImages;

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.formSection}>
              <h2 className={css.photosSectionTitle}><FormattedMessage id="ReloveEditListingForm.photosSectionTitle" /></h2>
              <p className={css.photosSectionSubtitle}><FormattedMessage id="ReloveEditListingForm.photosSectionSubtitle" /></p>
              <RelovePhotosDropzone
                images={images}
                onImageUpload={onImageUpload}
                onRemoveImage={onRemoveImage}
                updated={updated}
                updateInProgress={updateInProgress}
                ready={ready}
                form={form}
                fetchErrors={fetchErrors}
                disabled={disabled}
                intl={intl}
                onUpdateImageOrder={onUpdateImageOrder}
                imageOrder={imageOrder}
              />
            </div>
            <div className={css.formSection}>
              <FieldTextInput
                id="title"
                name="title"
                className={css.title}
                type="text"
                isPressEnterDisabled={true}
                label={titleMessage}
                placeholder={titlePlaceholderMessage}
                maxLength={TITLE_MAX_LENGTH}
                validate={composeValidators(required(titleRequiredMessage), maxLength60Message)}
                tooltip={titleTooltipMessage}
              />

              <FieldTextInput
                id="description"
                name="description"
                className={css.description}
                type="textarea"
                label={descriptionMessage}
                placeholder={descriptionPlaceholderMessage}
                validate={composeValidators(required(descriptionRequiredMessage))}
              />
            </div>
            <div className={css.formSection}>
              <div className={css.formFlex}>
                <ReloveCategorySelectField
                  categoryId="category"
                  categoryName="category"
                  categoryLabel={categoryLabel}
                  categoryPlaceholder={categoryPlaceholder}
                  categoryValidator={categoryRequired}
                  subCategoryId="subcategory"
                  subCategoryName="subcategory"
                  subCategoryLabel="Subcategoría"
                  subCategoryPlaceholder="Selecciona sub-categoría (opcional)"
                  form={form}
                  sizeName="size"
                  className={css.flexItem}
                  options={categoryOptions}
                />

                <FormSpy subscription={{ values: { category: true } }}
                  render={({ values }) => (
                    <ReloveSizeSelectField
                      id="size"
                      name="size"
                      className={css.flexItem}
                      label={sizeLabel}
                      placeholder={sizePlaceholder}
                      validate={sizeRequired}
                      options={values.category?.includes('Zapatos') ? shoesSizeOptions : values.category?.includes('Accesorios') ? accessoriesSizeOptions : clothesSizeOptions}
                    />
                  )} />
                <ReloveDatalistField
                  id="brand"
                  listId="brands"
                  name="brand"
                  className={css.flexItem}
                  label={brandLabel}
                  placeholder={brandPlaceholder}
                  validate={composeValidators(brandRequired, validateBrand)}
                  options={brandOptions}  // Use the updated options from MeiliSearch
                  onInputChange={searchBrands}  // Use the updated search function
                />
                <ReloveColorSelectField
                  id="color"
                  name="color"
                  className={css.flexItem}
                  label={colorLabel}
                  placeholder={colorPlaceholder}
                  validate={colorRequired}
                  options={colorOptions}
                />
                <ReloveFeatureSelectField
                  id="use"
                  name="use"
                  className={css.flexItem}
                  label={useLabel}
                  options={useOptions}
                  validator={useRequired}
                  selectProps={{
                    itemRenderer: customItemRenderer,
                    placeholder: usePlaceholder
                  }}
                />
                <ReloveFeatureSelectFieldSimple
                  id="style"
                  name="style"
                  className={css.flexItem}
                  label={styleLabel}
                  options={styleOptions}
                  placeholder={stylePlaceholder}
                />
              </div>
            </div>
            <div className={css.formSection}>
              <div className={css.formFlex}>
                <FieldCurrencyInput
                  id="original_price"
                  name="original_price"
                  className={css.flexItem}
                  label={originalPriceLabel}
                  placeholder={originalPricePlaceholder}
                  currencyConfig={config.currencyConfig}
                  validate={originalPriceRequired}
                />
                <FieldCurrencyInput
                  id="price"
                  name="price"
                  className={css.flexItem}
                  label={pricePerUnitMessage}
                  placeholder={pricePlaceholderMessage}
                  currencyConfig={config.currencyConfig}
                  validate={priceValidators}
                />
                <div className={classNames(css.formFlex, css.profitRow)} style={{ justifyContent: 'flex-end', width: '100%' }}>
                  <FieldTextInput
                    id="profit"
                    name="profit"
                    type="text"
                    className={css.flexItem}
                    inputRootClass={css.readOnlyField}
                    label="Tus Ganancias"
                    placeholder="Ganancias después de comisión"
                    readOnly
                  />
                </div>
                <div className={css.formFlex} style={{ width: '100%' }}>
                  <ReloveFeatureSelectFieldSimple
                    id="available"
                    name="available"
                    className={css.flexItem}
                    label={availableLabel}
                    placeholder={availablePlaceholder}
                    options={availableOptions}
                  />
                </div>
              </div>
            </div>
            {errorMessageCreateListingDraft}
            {errorMessageUpdateListing}
            {errorMessageShowListing}
            <div className={css.formSection}>
              <Button
                className={css.submitButton}
                type="submit"
                inProgress={submitInProgress}
                disabled={submitDisabled}
                ready={submitReady}
              >
                {saveActionMsg}
              </Button>
            </div>
          </Form>
        );
      }}
    />
  );
};

ReloveEditListingFormComponent.defaultProps = {
  className: null,
  fetchErrors: null,
  filterConfig: config.custom.filters,
};

ReloveEditListingFormComponent.propTypes = {
  className: string,
  intl: intlShape.isRequired,
  onSubmit: func.isRequired,
  saveActionMsg: string.isRequired,
  disabled: bool.isRequired,
  ready: bool.isRequired,
  updated: bool.isRequired,
  updateInProgress: bool.isRequired,
  fetchErrors: shape({
    publishListingError: propTypes.error,
    createListingDraftError: propTypes.error,
    showListingsError: propTypes.error,
    updateListingError: propTypes.error,
  }),
};

export default compose(injectIntl)(ReloveEditListingFormComponent);
